<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }}
        <b-button
          class="is-success is-pulled-right"
          @click="add()"
          icon-left="plus"
        >
          ເພີ່ມໃໝ່
        </b-button>
      </h1>
      <card-component class="has-table has-mobile-sort-spaced">
        <b-table
          :loading="isLoading"
          :paginated="perPage < models.length"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          default-sort="name"
          :data="models"
          :current-page="currentPage"
          :pagination-rounded="true"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index * currentPage + 1 }}
          </b-table-column>
          <b-table-column label="ຊື່" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column
            custom-key="actions"
            cell-class="is-actions-cell"
            v-slot="props"
          >
            <div class="buttons is-right">
              <b-button
                class="is-small is-warning"
                @click="edit(props.row)"
                icon-left="pencil"
              >
              </b-button>
              <b-button
                class="is-small is-danger"
                @click="remove(props.row)"
                icon-left="trash-can"
              >
              </b-button>
            </div>
          </b-table-column>

          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>

    <div class="modal is-active is-large" v-if="isShowModal">
      <div class="modal-background"></div>
      <div class="modal-card" v-if="model">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
          <button
            class="delete"
            aria-label="close"
            @click="isShowModal = false"
          />
        </header>
        <section class="modal-card-body">
          <b-field label="ຊື່" horizontal>
            <b-input v-model="model.name" placeholder="ຊື່" required />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            class="is-success"
            slot="right"
            @click="save()"
            icon-left="check"
          >
            ບັນທຶກ
          </b-button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import EmptyTable from "../components/EmptyTable.vue";
import CardComponent from "@/components/CardComponent";
export default {
  name: "ParcelTypes",
  components: { EmptyTable, CardComponent },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      title: "ປະເພດເຄື່ອງຝາກ",
      model: null,
      models: [],
      isShowModal: false,
      isLoading: false,
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.isLoading = true;
      Axios.get("parceltypes/gets")
        .then((r) => {
          this.isLoading = false;
          this.models = r;
        })
        .catch((e) => {
          this.isLoading = false;
          console.error(e);
        });
    },
    add() {
      this.model = {};
      this.isShowModal = true;
    },
    edit(item) {
      this.model = item;
      this.isShowModal = true;
    },
    remove(item) {
      this.$buefy.dialog.confirm({
        title: "ຢືນຢັນການລຶບຂ້ໍມູນ",
        message: "ທ່ານຕ້ອງການ <b>ລຶບ</b> ແທ້ບໍ?",
        confirmText: "ແມ່ນ, ລຶບ",
        cancelText: "ບໍ່",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          Axios.delete(`parceltypes/${item.id}`).then((r) => {
            console.log(r);
            this.get();
          });
        },
      });
    },
    save() {
      let req;
      if (this.model.id) {
        req = Axios.put("parceltypes", this.model);
      } else {
        req = Axios.post("parceltypes", this.model);
      }
      req.then((res) => {
        console.log(res);
        this.closeModal();
        this.get();
      });
    },
    closeModal() {
      this.isShowModal = false;
    },
  },
};
</script>
